import { render, staticRenderFns } from "./FileComponent.vue?vue&type=template&id=011d1fb6&scoped=true&"
import script from "./FileComponent.vue?vue&type=script&lang=js&"
export * from "./FileComponent.vue?vue&type=script&lang=js&"
import style0 from "./FileComponent.vue?vue&type=style&index=0&id=011d1fb6&scoped=true&lang=less&"
import style1 from "./FileComponent.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011d1fb6",
  null
  
)

export default component.exports