<template>
  <transition name="van-slide-up">
    <div class="file-preview-page" v-show="show">
      <div class="preview-page-title" v-if="showTitle">
        <span class="title-text">{{ file.fileName }}</span>
      </div>
      <div class="scroll-wrap" id="scrollWrap">
        <div class="preview-page-content" ref="previewContent" :style="{
          transformOrigin: `${style.transformOrigin.x}px ${style.transformOrigin.y}px`,
        }">
          <div class="top boundary" ref="top"></div>
          <div class="left boundary" ref="left"></div>
          <div class="right boundary" ref="right"></div>
          <div class="bottom boundary" ref="bottom"></div>
          <template v-for="(item, index) in oldPositionList">
            <template v-if="item.signType == 4">
              <div class="sealStyle24" v-for="(num, index2) in pageNum" :key="index + 'signType4' + index2" :style="{
                right: '0px',
                top: calcPositionY(item.y, num, true) + 'px',
                width: `${getWidthAndHeight(item.width / pageNum)}px`,
                height: `${getWidthAndHeight(item.height)}px`,
              }">
                <img :src="item.sealKey" :style="{
                  transform: `translate(-${getWidthAndHeight(
                    item.width / pageNum
                  ) *
                    (num - 1)}px`,
                }" alt="" />
              </div>
            </template>
            <template v-else>
              <div :class="item.signType > 2 ? 'sealStyle2' : 'signPosition2'" :key="index" :style="{
                left: item.x * ratio + 'px',
                top: calcPositionY(item.y, item.page, true) + 'px',
                width: `${getWidthAndHeight(item.width)}px`,
                height: `${getWidthAndHeight(item.height)}px`,
              }">
                <div v-if="item.dateFlag" class="time">
                  <img class="currenDateBase64" :src="textBecomeImg(item.signDate, 14, '#000')" alt="" />
                </div>
                <img :src="item.sealKey" alt="" />
              </div>
            </template>
          </template>
          <ul class="file-img-list" id="imgWrap" ref="imgWrap">
            <li class="file-img-item" v-for="(item, index) in imgList" :key="index">
              <img :src="item" alt="" :style="{ height: computedY + 'px' }" />
            </li>
            <li class="file-variable" v-for="variable in hasValueParamList" :key="variable.componentId" :style="{
              left: variable.x * ratio + 'px',
              top: calcPositionY(variable.y, variable.page, true) + 'px',
              width:
                variable.fontSize * ratio >= 12
                  ? variable.width * ratio + 'px'
                  : (variable.width * ratio) /
                  ((variable.fontSize * ratio) / 12) +
                  'px',
              height: variable.height * ratio + 'px',
              fontSize:
                variable.fontSize * ratio >= 12
                  ? variable.fontSize * ratio + 'px'
                  : 12 + 'px',
              fontFamily: getCurFontFamilyByValue(variable.font).name,
            }">
              <div :style="{
                transform: `scale(${variable.fontSize * ratio >= 12
                    ? 1
                    : (variable.fontSize * ratio) / 12
                  })`,
                display: 'inline-block',
                transformOrigin: '0 0',
              }">
                {{ variable.varValue ? variable.varValue : variable.varTitle }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <van-button v-if="showBack && isCurFileBeHandled" type="primary" class="footer-button"
          @click="handleChangeToFirstFileToBeProcess">已签署，切换下一份</van-button>
        <van-button v-else-if="showBack" type="primary" class="footer-button" @click="handleEmitBack">预览完毕
          开始签署</van-button>
        <template v-else>
          <van-button type="primary" class="footer-button" @click="close">退出预览</van-button>
          <div v-if="showDownload" type="primary" class="footer-button">
            <span class="operate-button" @click.stop="handleToDownload">下载</span>
            <!-- iOS 下载 自动触发链接的复制，让用户自己去外部浏览器下载 -->
            <!-- <span
              id="copy"
              :data-clipboard-text="copyUrl"
              v-clipboard:copy="copyUrl"
              v-clipboard:success="onCopy"
              @click.stop
              v-if="isAndroid() && isWeiXin()"
              >下载</span
            > -->
            <!-- iOS 下载 -->

            <!--       Android下载 微信会自动调用外部浏览器-->
            <!-- <a
              :download="fileName"
              :href="downloadUrl"
              target="_blank"
              @click.stop
              v-else-if="isAndroid()"
              >下载</a
            > -->
            <!--       Android下载 微信会自动调用外部浏览器-->

            <!--       在外部浏览器-->
            <!-- <span @click.stop="handleClickDownload" v-else>下载</span> -->
            <!-- 在外部浏览器 -->
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import { isIos, isAndroid, isWeiXin } from '@/utils/ua'
import { downloadFile } from '@/utils/downloadFile'
import { copy } from '@/utils/copy'
import { Dialog, Toast } from 'vant'
import { getCurFontFamilyByValue } from '@/common/font'
import { mapState } from 'vuex'
import { getStartPositionList } from '@/utils/handleSealPosition'
import contractSign from '@/api/contractSign'
import { translateSealWidthAndHeight } from '@/utils/pxtorem'
import { initPinchZoom } from '@/utils/pinchZoom'
import textBecomeImg from '@/utils/textBecomeImg'
import { calcRatio } from '@/utils/calcRatio'
import { getBodyClientRect, getFileImgHeight } from '@/utils/dom'
import { paginationHeight } from '@/common/filePreviewConfig'
import { isEmptyVariableValue } from '@/utils/helper'

export default {
  name: 'FilePreview',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: () => { },
    },

    // 是否展示当前的预览文件的标题，签署文件页面预览不需要展示
    showTitle: {
      type: Boolean,
      default: true,
    },

    // 展示返回签署按钮,签署文件页面预览需要展示返回签署按钮
    showBack: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      ratio2: state => state.contractSign.ratio2,
      // computedY: state => state.contractSign.computedY,
    }),
    imgList() {
      return this.file.imageList || this.file.fileKeyList
    },
    pageNum() {
      return this.imgList.length
    },
    status() {
      return this.file.status
    },
    // 是否当前文件已经被处理过了
    isCurFileBeHandled() {
      // 待处理的状态 只要不是这几个状态 就是处理完毕了
      const unOperateStatusList = [0, 1, 2, 3]
      return !unOperateStatusList.includes(this.status)
    },

    hasValueParamList() {
      if (this.comVarParamList && this.comVarParamList.length) {
        return this.comVarParamList.filter(
          param => !isEmptyVariableValue(param.varValue)
        )
      }
      return []
    },

    // 缩放后计算的 left 边界值，如果不缩放则就是0
    MAX_LEFT() {
      return this.style.scale > 1
        ? Math.ceil(((this.style.scale - 1) * this.wrapWidth) / 2)
        : 0
    },

    // 缩放后计算的 top 边界值，如果不缩放则就是0
    MAX_TOP() {
      return this.style.scale > 1 ? Math.ceil((this.style.scale - 1) * 54) : 0
    },
    downloadUrl() {
      return this.file?.downloadUrl
    },
    // 复制的要去下载页面的url
    copyUrl() {
      return `${window.location.origin}/download?downloadUrl=${this.downloadUrl}&fileName=${this.fileName}`
    },
    fileName() {
      return this.file?.fileName
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.open()
        } else {
          this.close()
        }
      },
      immediate: true,
    },
    show: {
      handler(newVal) {
        this.$emit('input', newVal)
        if (newVal) {
          this.initPinchZoom()
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      },
    },
    file: {
      handler(newValue) {
        this.fetchData()
        this.reset()
      },
    },
  },

  data() {
    return {
      wrapWidth: 0,
      show: false,
      total: undefined,
      oldPositionList: [],

      style: {
        transformOrigin: { x: 0, y: 0 },
      },

      reset: () => { },
      comVarParamList: [],
      ratio: 343 / 595,
      computedY: 530,
    }
  },
  created() {
    const width = document.documentElement.clientWidth
    const num = 32 * (width / 375)
    this.$store.commit('contractSign/setRatio', width - num)
  },
  mounted() {
    // 初始化手势库
    this.initPinchZoom()
    // 计算容器宽度
    this.$nextTick(() => {
      const { clientWidth } = getBodyClientRect()
      this.wrapWidth = clientWidth
    })
    this.ratio = calcRatio()
    this.computedY = getFileImgHeight()
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  methods: {
    handleToDownload() {
      try {
        contractSign
          .getDownLoadFileUrl({
            fileKey: this.file.fileKey,
          })
          .then(res => {
            const copyUrl = res
            if (isWeiXin()) {
              Dialog.confirm({
                title: '下载合同',
                message: '复制链接到浏览器打开，然后下载,有效期3分钟',
                confirmButtonText: '复制链接',
              })
                .then(() => {
                  this.$copyText(copyUrl)
                  this.$toast({
                    message:
                      '合同链接已复制成功，请前往浏览器打开，推荐使用百度，某些浏览器下载后可能会出现打不开的问题',
                  })
                })
                .catch(() => {
                  // on cancel
                })
              return
            }
            Dialog.confirm({
              title: '提示',
              message: '您要下载合同吗？',
            })
              .then(() => {
                const link = document.createElement('a')
                link.href = copyUrl
                link.click()
              })
              .catch(() => {
                // on cancel
              })
          })
      } catch (error) {
        console.error(error)
      }
    },
    isIos,
    isAndroid,
    isWeiXin,
    copy,
    getCurFontFamilyByValue,
    textBecomeImg,
    translateSealWidthAndHeight,
    getWidthAndHeight(value) {
      return this.translateSealWidthAndHeight(this.wrapWidth, value)
    },
    initState() {
      this.total = this.imgList.length
    },
    fetchData() {
      this.getSignAndDate()
    },
    open() {
      this.fetchData()
      this.show = true
    },
    close() {
      this.show = false
    },
    // 点击下载 IOS
    handleClickDownload() {
      console.log(11111)
      downloadFile(this.downloadUrl, this.fileName)
    },
    onCopy(e) {
      if (this.isWeiXin()) {
        Toast('复制链接成功，请到外部浏览器下载')
      }
    },
    async getSignAndDate() {
      try {
        if (this.file.fileId) {
          const model = {
            fileId: this.file.fileId,
          }
          const {
            oldPositionList,
            comVarParamList,
          } = await contractSign.getSignAndDate(model)
          this.oldPositionList = await getStartPositionList(oldPositionList)
          this.comVarParamList = comVarParamList
        } else if (this.file?.oldPositionList?.length) {
          this.oldPositionList = this.file.oldPositionList
        }
      } catch (error) {
        console.error(error)
      }
    },

    // 获取当前签章的类型
    currentSignPositionType(type) {
      if (type < 3) {
        return 1
      }
      return 2
    },

    initPinchZoom() {
      const { previewContent } = this.$refs
      const pz = initPinchZoom(previewContent, {
        minScale: 1,
        maxScale: 2,
      })
      this.reset = pz.resetMatrix
    },

    handleEmitBack() {
      this.$emit('onBack', { isCurFileBeHandled: this.isCurFileBeHandled })
    },

    handleChangeToFirstFileToBeProcess() {
      this.$store.dispatch('contractSign/changeToFirstFileToBeProcess')
    },

    calcPositionY(y, page, ratio) {
      if (ratio) {
        return (
          y * this.ratio +
          (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      }
      return (
        y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
      )
    },
  },
}
</script>

<style lang="less" scoped>
.file-preview-page {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 99;

  .preview-page-title {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;
    position: sticky;
    top: 0;
    box-shadow: 2px 0 3px #cccccc;
    z-index: 10;

    .title-text {
      width: 230px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title-close-button {
      position: absolute;
      right: 16px;
      color: @BLUE;
    }
  }

  .scroll-wrap {
    width: 100%;
    height: calc(100% - 48px);
    //padding: 0 16px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;

    .boundary {
      position: absolute;
    }

    .top {
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
    }

    .left {
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
    }

    .bottom {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
    }

    .right {
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
    }
  }

  .preview-page-content {
    position: absolute;
    width: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
    background: #f4f5f6;
    -webkit-overflow-scrolling: touch;
    transition: left 0.3s ease-out, top 0.3s ease-out;

    .page-item {
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 2px 2px #ccc;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .page-item+.page-item {
      margin-top: 16px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 8px calc(8px + env(safe-area-inset-bottom));
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    display: flex;
    align-items: center;
    background: @WHITE;
    box-sizing: border-box;

    .footer-button {
      width: 100%;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
      background-color: #1676ff;
      border: 1 solid #1676ff;
      border-radius: 4px;

      span,
      a {
        display: inline-block;
        height: 100%;
        width: 100%;
        text-align: center;
        line-height: 44px;
        color: #fff;
      }
    }

    .footer-button+.footer-button {
      margin-left: 10px;
    }
  }
}

.file-img-list {
  width: 100%;
  position: relative;

  .file-img-item {
    width: 100%;
    box-shadow: 0 0 2px 1px #ccc;

    img {
      width: 100%;
    }
  }

  .file-img-item+.file-img-item {
    margin-top: 20px;
  }

  .file-variable {
    position: absolute;
  }
}

.signPosition2 {
  position: absolute;
  z-index: 1;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #5f6368;
  line-height: 56px;
  //overflow: hidden;
  display: flex;
  // justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  .time {
    position: absolute;
    bottom: -30px;
    left: -1px;
    right: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 8px;
    white-space: nowrap;

    .currenDateBase64 {
      position: relative;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.sealStyle2 {
  z-index: 99;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #5f6368;
  line-height: 56px;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }

  .time {
    position: absolute;
    bottom: -30px;
    left: -1px;
    right: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 10px;
    white-space: nowrap;

    .currenDateBase64 {
      position: relative;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.sealStyle24 {
  z-index: 99;
  position: absolute;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #5f6368;
  line-height: 56px;
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    height: 100%;
  }
}
</style>
