<template>
  <van-dialog
    class="partner-edit-dialog"
    v-model="show"
    title="修改参与方信息"
    show-cancel-button
    append-to-body
    @confirm="onConfirm"
    @cancel="onCancel"
  >
    <div class="form-content" v-if="!isPersonal">
      <div class="form-item">
        <div class="form-label">企业名称</div>
        <input class="form-input" v-model="comModifyModel.comName" />
      </div>
      <div class="form-item">
        <div class="form-label">经办人</div>
        <input class="form-input" v-model="comModifyModel.handlerName" />
      </div>
      <div class="form-item">
        <div class="form-label">联系方式</div>
        <input class="form-input" v-model="comModifyModel.handlerTel" />
      </div>
    </div>
    <div class="form-content" v-else>
      <div class="form-item">
        <div class="form-label">个人名称</div>
        <input class="form-input" v-model="personModifyModel.handlerName" />
      </div>
      <div class="form-item">
        <div class="form-label">联系方式</div>
        <input class="form-input" v-model="personModifyModel.handlerTel" />
      </div>
    </div>
  </van-dialog>
</template>

<script>
  export default {
    name: 'PartnerInfoEdit',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      info: {
        type: Object,
        default: () => {},
      },
      partnerRole: {
        type: Number,
        default: 0,
      },
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue) {
            this.open()
          } else {
            this.close()
          }
        },
        immediate: true,
      },
      show: {
        handler(newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    computed: {
      // 1-外部个人 2-外部企业
      isPersonal() {
        return this.partnerRole === 1
      },
    },
    data() {
      return {
        show: true,
        comModifyModel: {
          comName: this.info?.comName,
          handlerName: this.info?.handlerName,
          handlerTel: this.info?.handlerTel,
        },
        personModifyModel: {
          handlerName: this.info?.handlerName,
          handlerTel: this.info?.handlerTel,
        },
        loading: false,
      }
    },
    methods: {
      open() {
        this.show = true
      },
      close() {
        this.show = false
      },

      onConfirm() {
        if (this.isPersonal) {
          this.$emit('onConfirm', this.personModifyModel)
          return
        }
        this.$emit('onConfirm', this.comModifyModel)
      },
      onCancel() {
        this.close()
      },
    },
  }
</script>

<style lang="less" scoped></style>
<style lang="less">
  .partner-edit-dialog {
    .van-dialog__header {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 24px;
    }
    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 24px;
      .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 17px;
        .form-label {
          width: 56px;
          font-size: 14px;
          text-align: right;
          color: @TEXT-COLOR-1;
          margin-right: 8px;
        }
        .form-input {
          width: 210px;
          height: 42px;
          padding: 11px 8px;
          color: @TEXT-COLOR-0;
          font-size: 14px;
          border: 1px solid #e2e4ea;
          border-radius: 3px;
        }
      }
      .form-item:last-child {
        margin-bottom: 0;
      }
    }
    /deep/ .van-dialog__footer {
      .van-button {
        height: 49px;
        font-size: 18px;
      }
      .van-dialog__confirm {
        color: @BLUE;
      }
    }
  }
</style>
