<template>
  <div
    class="file-component"
    :class="{ 'blue-bg': isBlue, 'grey-bg': isGrey }"
    @click="handlePreviewFile"
  >
    <SvgIcon :type="getFileType" class="header-img" />
    <div class="file-content">
      <div class="file-name" :title="fileName">
        <div>{{ fileName }}</div>
        <div class="file-size">{{ fileSize }}</div>
      </div>
      <div class="file-operate">
        <span
          class="operate-button"
          @click.stop="handleToDownload"
          v-if="showDownload"
          >下载</span
        >
        <!-- iOS 下载 自动触发链接的复制，让用户自己去外部浏览器下载 -->
        <!-- <span
          class="operate-button"
          id="copy"
          :data-clipboard-text="copyUrl"
          v-clipboard:copy="copyUrl"
          v-clipboard:success="onCopy"
          @click.stop
          v-if="isAndroid() && isWeiXin() && showDownload"
          >下载</span
        > -->
        <!-- iOS 下载 -->

        <!--       Android下载 微信会自动调用外部浏览器-->
        <!-- <a
          class="operate-button"
          :download="fileName"
          :href="downloadUrl"
          target="_blank"
          @click.stop
          v-else-if="isAndroid() && showDownload"
          >下载</a
        > -->
        <!--       Android下载 微信会自动调用外部浏览器-->

        <!--       在外部浏览器-->
        <!-- <span
          class="operate-button"
          @click.stop="handleClickDownload"
          v-else-if="showDownload"
          >下载</span
        > -->
        <!-- 在外部浏览器 -->
        <span class="operate-button disabled">
          {{ curOperateButtonText }}
        </span>
        <!-- <span
          class="operate-button"
          v-else-if="!isButtonDisabled"
          @click.stop="handleClickOperateButton"
        >
          {{ curOperateButtonText }}
        </span> -->
      </div>
    </div>
    <FilePreview
      append-to-body
      v-model="showPreview"
      :file="file"
      :showDownload="signStatus == 2 || showDownload"
    />

    <!-- 未实名签署提示 -->
    <van-dialog
      class="sign-detail-dialog"
      append-to-body
      title="提示"
      show-cancel-button
      v-if="showRealNameDialog"
      v-model="showRealNameDialog"
      @close="showRealNameDialog = false"
    >
      <p class="dialog-description">
        无法签署，请先提醒企业创建者/法人进行企业认证!
      </p>
    </van-dialog>
    <!-- 未实名签署提示 -->
  </div>
</template>

<script>
  import { isIos, isAndroid, isWeiXin } from '@/utils/ua'
  import { downloadFile } from '@/utils/downloadFile'
  import { copy } from '@/utils/copy'
  import { Dialog, Toast } from 'vant'
  import ClipboardJS from 'clipboard'
  import contractSignApi from '@/api/contractSign'
  import FilePreview from './FilePreview.vue'

  export default {
    name: 'FileComponent',
    components: { FilePreview },
    props: {
      file: {
        type: Object,
        required: true,
        default: () => {},
      },
      // 是否展示下载按钮
      showDownload: {
        type: Boolean,
        default: false,
      },
      // 是否为蓝色背景
      // 如果是相关文件则需要展示背景色
      isBlue: {
        type: Boolean,
        default: false,
      },
      businessId: {
        type: String,
        default: undefined,
      },
      // 是否为发起方
      isLauncher: {
        type: Boolean,
        default: false,
      },
      // 是否允许不实名签署 0-否 1-是
      noCreditSign: {
        type: Number,
        default: 1,
      },
      // 当前参与方是否已经实名
      realName: {
        type: Boolean,
        default: true,
      },
      // 当前文件能否操作
      canOperate: {
        type: Boolean,
        default: false,
      },
      // 文件背景是否为灰色
      isGrey: {
        type: Boolean,
        default: false,
      },
      // 合同状态  0.草稿 1.未完成 2.已完成 3.已失效 4.已失效-过期 5.已失效-拒签 6.已失效-撤回
      signStatus: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      subStatus() {
        return this.file?.subStatus
      },
      subId() {
        return this.file?.subId
      },
      curOperateButtonText() {
        // 可下载 则说明是参考资料 不可操作
        if (this.showDownload && !this.canOperate) {
          return undefined
        }
        if (this.isBlue && this.subStatus === null) {
          return '无需处理'
        }
        // if (!this.canOperate && !this.isCompletedStatus) {
        //   return '待签署'
        // }
        return this.subStatusMap[this.subStatus]?.title
      },
      fileName() {
        return this.file?.fileName
      },
      fileSize() {
        return this.file?.fileSize
      },
      downloadUrl() {
        return this.file?.downloadUrl
      },

      isCompletedStatus() {
        const { subStatus } = this

        return (
          subStatus === 10 ||
          subStatus === 11 ||
          subStatus === 12 ||
          subStatus === 13
        )
      },
      // 按钮是否禁用
      isButtonDisabled() {
        const { subStatus } = this
        if (this.showDownload) {
          return false
        }
        return (
          !this.canOperate ||
          subStatus === 10 ||
          subStatus === 11 ||
          subStatus === 12 ||
          subStatus === 13
        )
      },
      // 复制的要去下载页面的url
      copyUrl() {
        return `${window.location.origin}/download?downloadUrl=${this.downloadUrl}&fileName=${this.fileName}`
      },
      getFileType() {
        let str = ''
        if (
          this.file.fileFormat === 'jpg' ||
          this.file.fileFormat === 'png' ||
          this.file.fileFormat === 'svg'
        ) {
          str = 'jpeg'
        } else if (
          this.file.fileFormat === 'doc' ||
          this.file.fileFormat === 'docx'
        ) {
          str = 'word'
        } else if (this.file.fileFormat === 'pdf') {
          str = 'pdf'
        } else if (this.file.fileFormat === 'ppt') {
          str = 'ppt'
        } else {
          str = 'default'
        }
        return str
      },
    },
    data() {
      // 文件操作状态 0.待填写 1.待审批 2.待签署 3.待签章 10.已填写 11.已审批 12.已签署 13.已签章
      this.subStatusMap = {
        0: {
          title: '待填写',
          buttonText: '去填写',
        },
        1: {
          title: '待审批',
          buttonText: '审批',
        },
        2: {
          title: '待签署',
          buttonText: '签署',
        },
        3: {
          title: '待签章',
          buttonText: '签章',
        },
        10: {
          title: '已填写',
          buttonText: '已填写',
        },
        11: {
          title: '已审批',
          buttonText: '已审批',
        },
        12: {
          title: '已签署',
          buttonText: '已签署',
        },
        13: {
          title: '已签章',
          buttonText: '已签章',
        },
      }
      return {
        // 是否展示文件预览
        showPreview: false,
        showRealNameDialog: false,
      }
    },
    created() {},
    methods: {
      handleToDownload() {
        try {
          contractSignApi
            .getDownLoadFileUrl({
              fileKey: this.file.fileKey,
            })
            .then(res => {
              const copyUrl = res
              if (isWeiXin()) {
                Dialog.confirm({
                  title: '下载合同',
                  message: '复制链接到浏览器打开，然后下载,有效期3分钟',
                  confirmButtonText: '复制链接',
                })
                  .then(() => {
                    this.$copyText(copyUrl)
                    this.$toast({
                      message:
                        '合同链接已复制成功，请前往浏览器打开，推荐使用百度，某些浏览器下载后可能会出现打不开的问题',
                    })
                  })
                  .catch(() => {
                    // on cancel
                  })
                return
              }
              Dialog.confirm({
                title: '提示',
                message: '您要下载合同吗？',
              })
                .then(() => {
                  const link = document.createElement('a')
                  link.href = copyUrl
                  link.click()
                })
                .catch(() => {
                  // on cancel
                })
            })
        } catch (error) {
          console.error(error)
        }
      },
      isIos,
      isAndroid,
      isWeiXin,
      copy,
      // 点击下载 IOS
      handleClickDownload() {
        downloadFile(this.downloadUrl, this.fileName)
      },

      onCopy(e) {
        if (this.isWeiXin()) {
          Toast('复制链接成功，请到外部浏览器下载')
        }
      },

      // 当前参与方是否要去认证
      async getComOrUserStatus() {
        try {
          // console.log(this.file)
          const model = {
            partnerSubId: this.subId,
          }
          return await contractSignApi.getComOrUserStatus(model)
        } catch (error) {
          console.error(error)
        }
      },

      async handleClickOperateButton() {
        try {
          const creditRes = await this.getComOrUserStatus()
          // creditStatus 认证状态true：认证成功 false：认证失败
          // creditType 认证类型 0：企业 1个人
          const { creditStatus, creditType } = creditRes
          if (!creditStatus) {
            // 没有认证
            if (creditType === 1) {
              // 个人认证
              Dialog.alert({
                title: '提示',
                message: '您还没有认证,请先去实名认证',
                confirmButtonText: '去认证',
                className: 'sign-detail-dialog',
              }).then(() => {
                this.$router.push({
                  path: 'signAuth',
                  query: {
                    url: this.$route.fullPath,
                  },
                })
              })
            } else if (creditType === 0) {
              // 企业未认证
              await Dialog.alert({
                title: '提示',
                message: '无法签署，请先提醒企业创建者/法人进行企业认证!',
                confirmButtonText: '确定',
                className: 'sign-detail-dialog',
              })
            }
          } else {
            // 已经认证
            if (this.subId) {
              const query = {
                subId: this.subId,
                isLauncher: this.isLauncher ? '1' : '0',
                businessId: this.businessId,
              }
              await this.$router.push({ name: 'signFile', query })
            }
          }
        } catch (error) {
          console.error(error)
        }
      },

      handlePreviewFile() {
        // 点击进入签署页的条件
        // 1. 不是下载
        // 2. 按钮可用
        // if (!this.isBlue && !this.showDownload && !this.isButtonDisabled) {
        //   this.handleClickOperateButton()
        //   return
        // }

        // 进入预览页的条件
        // 1. 相关文件列表
        // 2. 展示下载按钮
        // // 3. 文件签署状态已经完成 或 当前是发起方
        // if (
        //   this.isBlue ||
        //   this.showDownload ||
        //   this.isLauncher ||
        //   this.isButtonDisabled
        // ) {
        // 所有文件操作都是预览
        if (this.file.imageList) {
          this.showPreview = true
        }
        // }
      },
    },
  }
</script>

<style scoped lang="less">
  .file-component {
    width: 100%;
    padding: 11px 8px;
    background: @WHITE;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    &.blue-bg {
      // background: rgba(22, 118, 255, 0.05);
      background: #f4f5f6;
      border: none;
    }
    &.grey-bg {
      background: #f4f5f6;
      border: none;
      .disabled {
        color: #111a34 !important;
      }
    }
    .header-img {
      font-size: 28px;
    }
    .file-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .file-name {
      width: 160px;
      font-size: 14px;
      line-height: 20px;
      color: @TEXT-COLOR-0;
      padding-left: 6px;
      div {
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .file-size {
        font-size: 11px;
        color: #747980;
      }
    }
    .file-operate {
      color: #111a34;
      font-size: 14px;
      line-height: 20px;
      .operate-button {
        color: #111a34;
        font-size: 14px;
        // border: 1px solid #1676ff;
        // padding: 2px 5px;
        // border-radius: 4px;
        &.disabled {
          color: #677283;
          pointer-events: none;
          border: none;
        }
      }
    }
  }
</style>

<style lang="less">
  .sign-detail-dialog {
    width: 306px;
    border-radius: 6px;

    .van-dialog__header {
      font-size: 20px;
      font-weight: 400;
    }

    .van-dialog__content {
      margin-top: 24px;
      padding: 0 24px;

      .dialog-description {
        font-size: 16px;
        color: @TEXT-COLOR-1;
      }

      .van-cell {
        margin-top: 24px;
        padding: 0;

        .van-field__body {
          .van-field__control {
            border: 1px solid #e2e4ea;
            border-radius: 4px;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .van-dialog__footer--buttons {
      margin-top: 20px;

      .van-button {
        height: 50px;
      }
    }
  }
</style>
