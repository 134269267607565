<template>
  <van-overlay class-name="join-qr-code-container" :show="show" @click="close">
    <div class="join-qr-code" @click.stop>
      <div class="main-container">
        <div class="main-header">
          <div class="header-title">二维码/链接邀请</div>
          <div class="header-description">
            您可以长按分享海报或复制链接邀请参与方
          </div>
        </div>
        <div class="main-body">
          <img
            class="main-body-bg"
            src="@/assets/imgs/signFile/join_bg.png"
            alt="background"
          />
          <div class="main-body-content">
            <div class="content-top">
              <div class="top-title">
                <div class="title-text">{{ detail.businessTitle }}</div>
                <img :src="systemInfo.logo2" class="icon_logo" />
              </div>
              <div class="title-description">
                <div class="description-item">
                  发起人：{{ detail.launcher }}
                </div>
                <div class="description-item">参与方：{{ partnerName }}</div>
              </div>
            </div>
            <div class="content-bottom">
              <div class="qr-code">
                <!--  canvas 不能触发微信的长按识别二维码  -->
                <!--  <canvas class="qr-code-canvas" ref="qrCodeCanvas"></canvas>-->
                <img :src="dataUrl" class="qr-code-canvas" alt="qrCode" />
              </div>
              <p class="bottom-text">长按识别二维码获取文件</p>
            </div>
          </div>
        </div>
        <div class="main-footer">
          <div class="link-container">
            {{ url }}
          </div>
          <div class="copy-button" @click="handleCopyLink">复制链接</div>
        </div>
      </div>
    </div>
    <div class="close-button" @click="close">
      <SvgIcon type="close" class="icon-close" />
    </div>
  </van-overlay>
</template>

<script>
  import { mapState } from 'vuex'
  import QrCode from 'qrcode'

  export default {
    name: 'JoinQrCode',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      detail: {
        type: Object,
        default: () => {},
      },
      partnerInfo: {
        type: Object,
        default: () => {},
      },
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue) {
            this.open()
          } else {
            this.close()
          }
        },
        immediate: true,
      },
      show: {
        handler(newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    computed: {
      isPersonal() {
        return this.partnerInfo.partnerRole === 1
      },
      partnerId() {
        return this.partnerInfo.partnerId
      },
      partnerName() {
        if (this.isPersonal) {
          return this.partnerInfo.handlerName
        }
        return this.partnerInfo.comName
      },
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    data() {
      return {
        show: false,
        url: undefined,
        dataUrl: undefined,
      }
    },
    mounted() {
      this.generateQrCode()
    },
    methods: {
      open() {
        this.show = true
      },
      close() {
        this.show = false
      },
      async generateQrCode() {
        this.$nextTick(() => {
          const url = `${window.location.origin}/addSign?partnerId=${this.partnerId}&fileStatus=${this.detail.status}`
          this.url = url
          const { qrCodeCanvas } = this.$refs
          QrCode.toDataURL(url, {}, (err, res) => {
            if (err) {
              console.error(err)
            } else {
              this.dataUrl = res
            }
          })
        })
      },

      async handleCopyLink() {
        try {
          await this.$copyText(this.url)
          this.$toast({
            message: '复制链接成功',
          })
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .join-qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .join-qr-code {
    background: @WHITE;
    width: 306px;
    padding: 24px 16px;
    border-radius: 6px;
    animation: slideUp 0.4s ease;
    .main-container {
      .main-header {
        text-align: center;
        .header-title {
          font-size: 20px;
          color: @TEXT-COLOR-0;
          line-height: 20px;
        }
        .header-description {
          color: @TEXT-COLOR-1;
          font-size: 13px;
          line-height: 24px;
          margin-top: 16px;
        }
      }
    }
    .main-body {
      margin-top: 10px;
      position: relative;
      width: 271px;
      height: 318px;
      animation: slideUpSmall 0.25s ease both;
      animation-delay: 0.3s;
      .main-body-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      .main-body-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 232px;
        height: 284px;
        display: flex;
        flex-direction: column;
        z-index: 10;
        .content-top {
          transition: all 0.25s ease;
          padding: 18px;
          box-sizing: border-box;
          width: 100%;
          height: 113px;
          position: relative;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          background: radial-gradient(
                circle at left bottom,
                transparent 10px,
                @WHITE 0
              )
              left bottom / 50% 100% no-repeat,
            radial-gradient(circle at right bottom, transparent 10px, @WHITE 0)
              right bottom / 50% 100% no-repeat;

          &::after {
            position: absolute;
            width: 200px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            bottom: 0;
            border: 1px dashed #b9d6ff;
          }
          .top-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-text {
              color: @TEXT-COLOR-0;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .icon_logo {
              height: 24px;
            }
          }
          .title-description {
            display: flex;
            flex-direction: column;
            .description-item {
              margin-top: 8px;
              font-size: 12px;
              color: #404c56;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .content-bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 169px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          background: radial-gradient(
                circle at left top,
                transparent 10px,
                @WHITE 0
              )
              left top / 50% 100% no-repeat,
            radial-gradient(circle at right top, transparent 10px, @WHITE 0)
              right top / 50% 100% no-repeat;

          .qr-code {
            margin-top: 10px;
            animation: fade 0.25s ease both;
            animation-delay: 0.5s;
            .qr-code-canvas {
              width: 130px;
              height: 130px;
            }
          }
          .bottom-text {
            font-size: 12px;
            color: @TEXT-COLOR-1;
          }
        }
      }
    }

    .main-footer {
      margin-top: 13px;
      width: 274px;
      height: 40px;
      border: 1px solid @BORDER-COLOR-0;
      border-radius: 2px;
      display: flex;
      align-items: center;
      animation: fade 0.25s ease both;
      animation-delay: 0.3s;
      .link-container {
        width: 194px;
        padding: 0 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 13px;
        color: @TEXT-COLOR-1;
        border-right: 1px solid @BORDER-COLOR-0;
      }
      .copy-button {
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: @BLUE;
      }
    }
  }
  .close-button {
    margin-top: 24px;
    .icon-close {
      font-size: 24px;
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUpSmall {
    from {
      opacity: 0;
      transform: translateY(40%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
