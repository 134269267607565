<template>
  <div class="step">
    <!-- <div class="step-left">
      <SvgIcon type="launcher" class="icon" v-if="!isFailed && isLauncher" />
      <SvgIcon
        type="launcher_failed"
        class="icon"
        v-else-if="isFailed && isLauncher"
      />
      <SvgIcon type="failed" class="icon" v-else-if="isFailed" />
      <SvgIcon :type="statusMap[status]" class="icon" v-else />
      <div
        class="step-line"
        :class="{ success: status === 3 || isLauncher }"
        v-if="!isLast && isOrdered"
      ></div>
    </div> -->
    <div class="step-right">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step',
    props: {
      // 是否为有顺序的
      // 如果是无序的，则不展示line
      isOrdered: {
        type: Boolean,
        default: false,
      },
      // launcher not_join pending_current pending_next success
      status: {
        type: [Number, String],
        default: 0,
      },
      // 是否为最后一项
      isLast: {
        type: Boolean,
        default: false,
      },
      curIndex: {
        type: Number,
        default: 0,
      },
      isFailed: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isLauncher() {
        return this.status === 'launcher'
      },
      // 获取当前流程在总流程中的index
      curStepIndex() {
        return this.status === 3 && this.curIndex
      },
    },
    data() {
      this.statusMap = {
        0: 'not_join',
        1: 'pending_next',
        2: 'pending_current',
        3: 'success',
        4: 'invalid_status',
      }
      return {}
    },
  }
</script>

<style lang="less" scoped>
  .step {
    // display: flex;
    height: 100%;
    .step-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      .icon {
        line-height: 14px;
        width: 14px;
        height: 14px;
        font-size: 14px;
      }
      .step-line {
        flex: 1;
        display: flex;
        width: 1px;
        margin-top: 2px;
        margin-bottom: 3px;
        background: #8a9ab4;
        opacity: 0.2;
        &.success {
          background: #0371ff;
          opacity: 0.2;
        }
      }
    }
  }
</style>
