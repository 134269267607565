<template>
  <div class="sign-detail-page">
    <div
      class="sign-detail-tips"
      id="sign-detail-tips"
      :class="{ expanded: isTipsExpanded }"
      ref="signDetailTips"
      v-show="detail.noCreditSign === 1 && detail.noWaterLineStatus === 0"
    >
      <SvgIcon type="icon_warn" class="item-icon" />
      <p class="tips-content">{{ tipsText }}</p>
      <div class="tips-button" @click="handleToggleExpand">
        {{ tipsButtonText }}
      </div>
    </div>
    <div
      class="sign-detail-header"
      id="sign-detail-header"
      ref="signDetailHeader"
    >
      <div class="header-card" :class="statusClass">
        <div class="card-title">
          <span class="card-title-text" :class="{ ellipsis: !isDescExpanded }">
            {{ detail.businessTitle }}
          </span>
          <span class="card-title-status">
            <!-- <SvgIcon
              :type="statusMapList[detail.status].icon"
              class="status-icon"
            /> -->
            <span class="status-text" :class="statusClass">{{
              statusMapList[detail.status].text
            }}</span>
          </span>
        </div>
        <div class="card-desc" v-if="isDescExpanded">
          <!-- <span class="desc-content-title">请每位同事</span> -->
          <span class="desc-content" :class="{ expanded: isDescExpanded }">
            {{ detail.descName }}
          </span>
        </div>
        <!-- 发起人可见 抄送人 通知方式 -->
        <div class="launcher-info" v-if="isLauncher && isDescExpanded">
          <div class="send-user info-item" v-if="detail.sendUserList.length">
            <div class="info-item-title">
              <!-- <SvgIcon type="icon_send" class="item-icon" /> -->
              文件签署完毕后将抄送给
            </div>
            <ul class="send-user-list" v-if="detail.sendUserList.length">
              <li
                class="user-list-item"
                v-for="user in detail.sendUserList"
                :key="user.uid"
              >
                <img :src="user.headImg" v-if="user.headImg" />
                <img src="@/assets/imgs/person.svg" v-else />
                <!-- <SvgIcon type="person" class="user-item-img" v-else /> -->
                <span>{{ user.userName || user.telephone }}</span>
              </li>
            </ul>
            <div class="send-user-list" v-else>无</div>
          </div>
          <div class="info-item">
            <div class="info-item-title">
              <!-- <SvgIcon type="icon_notice" class="item-icon" /> -->
              通知方式
            </div>
            <div class="info-item-text">
              {{ noticeWayMap[detail.noticeWay] }}
            </div>
          </div>
        </div>
        <!-- 发起人可见 抄送人 通知方式 -->

        <div class="info-item" :class="{ borT: !isDescExpanded }">
          <div class="info-item-title">
            <!-- <SvgIcon type="icon_end_time" class="item-icon" /> -->
            截止日期
          </div>
          <div class="info-item-text">
            {{ (detail.byTime * 1000) | datetimeFormat }}
          </div>
        </div>

        <div class="expand-arrow" @click="handleToggleExpandDesc">
          {{ isDescExpanded ? '收起' : '展开' }}
          <van-icon name="arrow-down" :class="{ reverse: isDescExpanded }" />
          <!-- <SvgIcon type="icon_up"  :class="{ reverse: isDescExpanded }"/> -->
        </div>
        <!-- 催签 -->
        <!-- <div class="urge" v-if="urgeVisible && detail.signOrderFlag != 1">
          <van-button
            type="info"
            :disabled="urgeVisible == 2"
            @click="handleUrgeAll"
          >
            {{ urgeVisible == 1 ? '批量催签' : '已催签' }}
          </van-button>
        </div> -->
      </div>
    </div>
    <div class="sign-detail-main" id="sign-detail-main" ref="signDetailMain">
      <van-tabs line-width="56px" v-model="active" swipeable animated sticky>
        <van-tab key="0" title="签署流程">
          <div class="step-container">
            <!-- 发起方 -->
            <Step
              key="launcher"
              status="launcher"
              :is-failed="isFailed"
              :isOrdered="!!detail.signOrderFlag"
            >
              <Partner
                :detail="detail"
                :info="launcherInfo"
                is-pure-launcher-component
              />
            </Step>
            <!-- 发起方 -->
            <Step
              v-for="(partner, index) in detail.partnerList"
              :curIndex="index"
              :key="partner.partnerId"
              :is-failed="isFailed"
              :status="isFailed ? 4 : partner.status"
              :isOrdered="!!detail.signOrderFlag"
              :isLast="index === detail.partnerList.length - 1"
            >
              <Partner
                :detail="detail"
                :info="partner"
                :isCur="partner.isSelf"
              />
            </Step>
            <div class="file-list-container file-list-container-new">
              <div class="files">
                <div class="title">相关文件</div>
                <!-- 相关文件只有在签署状态为完成的状态才能下载 -->
                <FileComponent
                  v-for="file in detail.fileRespList"
                  :key="file.fileKey"
                  :file="file"
                  :isBlue="true"
                  :show-download="curStatus === 2 || curStatus === 7"
                />
                <!-- 相关文件只有在签署状态为完成的状态才能下载 -->
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab key="1" title="相关文件">
          <div class="step-container">
            <div class="file-list-container">
              <div class="files">
                <div class="title">文件</div>
                <!-- 相关文件只有在签署状态为完成的状态才能下载 -->
                <FileComponent
                  v-for="file in detail.fileRespList"
                  :key="file.fileKey"
                  :file="file"
                  :isBlue="true"
                  :show-download="curStatus === 2 || curStatus === 7"
                />
                <!-- 相关文件只有在签署状态为完成的状态才能下载 -->
              </div>
              <div class="relate-files" v-if="detail.fileRelatedList.length">
                <div class="title">参考资料</div>
                <FileComponent
                  v-for="file in detail.fileRelatedList"
                  :key="file.fileKey"
                  :file="file"
                  :show-download="true"
                  :isBlue="true"
                />
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 未完成状态才展示button -->
    <div
      class="sign-detail-footer"
      ref="footer"
      v-if="showOperateButton || (urgeVisible && detail.signOrderFlag != 1)"
    >
      <div
        class="footer-btn"
        v-if="showRevokeButton"
        @click="handleShowRevokeDialog"
      >
        <SvgIcon type="icon_back" class="icon-btn" />
        <span>撤回</span>
      </div>
      <div
        class="footer-btn"
        v-else-if="showReturnApproveButton"
        @click="handleShowReturnApproveDialog"
      >
        <SvgIcon type="icon_back" class="icon-btn" />
        <span>拒签</span>
      </div>
      <div
        class="footer-btn"
        v-if="urgeVisible && detail.signOrderFlag != 1"
        @click="handleUrgeAll"
      >
        <SvgIcon type="icon_urge" class="icon-btn" />
        <span>{{ urgeVisible == 1 ? '催签' : '已催签' }}</span>
      </div>
      <!-- <van-button
        class="btn-large"
        type="primary"
        v-if="showRevokeButton"
        @click="handleShowRevokeDialog"
        >撤回
      </van-button> -->
      <!-- <van-button
        type="primary"
        v-if="showReturnApproveButton"
        @click="handleShowReturnApproveDialog"
        >拒签
      </van-button> -->
      <!-- <van-button
        plain
        type="primary"
        v-if="showRevokeButton"
        @click="handleShowJoinQrCode"
        >邀请参与方
      </van-button> -->
      <van-button
        type="primary"
        v-if="showOperateFirstButton"
        @click="handleOperateFirstFile"
        >查看并签署
      </van-button>
    </div>
    <!-- 未完成状态才展示button -->
    <!-- 作废 -->
    <div class="sign-detail-footer" v-if="isNullifyFalg">
      <div
        class="footer-btn"
        v-if="showReturnNullifyButton"
        @click="handleShowReturnApproveDialog"
      >
        <SvgIcon type="icon_back" class="icon-btn" />
        <span>拒签</span>
      </div>
      <van-button
        type="primary"
        v-if="showOperateFirstButton"
        @click="handleOperateFirstFile"
        >查看并签署
      </van-button>
    </div>
    <!-- 作废 -->
    <template v-else>
      <div class="sign-detail-footer" v-if="isCompletedStatus">
        <van-button
          class="btn-large"
          type="primary"
          @click="handleToEvidencePage"
          >查看凭证报告</van-button
        >
      </div>
      <div class="sign-detail-footer" v-if="isFailed && isLauncher">
        <van-button class="btn-large" type="primary" @click="handleDeleteFile"
          >删除
        </van-button>
      </div>
      <div
        class="sign-detail-footer"
        v-if="
          hasFileDelete &&
            (curStatus == 0 || curStatus >= 3) &&
            !isCompletedStatus
        "
      >
        <van-button class="btn-large" type="primary" @click="handleDelete"
          >删除
        </van-button>
      </div>
    </template>
    <!-- 撤回文件 -->
    <van-dialog
      class="sign-detail-dialog"
      append-to-body
      title="撤回文件"
      show-cancel-button
      v-if="showRevokeDialog"
      v-model="showRevokeDialog"
      @confirm="revoke"
      @cancel="onRevokeDialogClose"
      @close="onRevokeDialogClose"
    >
      <p class="dialog-description">
        撤回后本次签署将终止，文件失效，是否确认撤回
      </p>
      <van-field
        v-model="revokeModel.revokeReason"
        maxlength="300"
        rows="3"
        border
        autosize
        type="textarea"
        placeholder="请输入撤回原因(300字以内)"
      />
    </van-dialog>
    <!-- 撤回文件 -->

    <!-- 拒签 -->
    <van-dialog
      class="sign-detail-dialog"
      append-to-body
      :title="isNullifyFalg ? '拒绝作废' : '文件拒签'"
      show-cancel-button
      v-if="showReturnApproveDialog"
      v-model="showReturnApproveDialog"
      @confirm="handleShowSetPasswordComponent"
      @cancel="onReturnApproveDialogClose"
      @close="onReturnApproveDialogClose"
    >
      <p class="dialog-description">
        {{
          isNullifyFalg
            ? '执行【拒绝作废】操作，表示您将终止本次签署，请确认是否执行本次操作？'
            : '拒签操作表示您拒绝签署此文件，本次签署终止，文件失效，是否拒签？'
        }}
      </p>
      <van-field
        v-model="returnApproveModel.revokeReason"
        maxlength="300"
        rows="3"
        border
        autosize
        type="textarea"
        :placeholder="
          isNullifyFalg
            ? '请输入拒绝作废原因(300字以内)'
            : '请输入拒签原因(300字以内)'
        "
      />
    </van-dialog>
    <!-- 拒签 -->

    <SetPassWord @confirm="onSetPasswordConfirm" :signWay="detail.signWay" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { hasAuth } from '@/utils/hasAuth'
  import contractBusinessApi from '@/api/contractBusiness'
  import contractSignApi from '@/api/contractSign'
  import SetPassWord from '@/components/sign/setPassWord.vue'
  import { Dialog } from 'vant'
  import { isWxMiniProgram } from '@/utils/ua'
  import { postShareMessageToMini } from '@/utils/mini'
  import Partner from './Components/Partner.vue'
  import Step from './Components/Step.vue'
  import FileComponent from './Components/FileComponent.vue'

  export default {
    name: 'SignDetail',
    components: {
      FileComponent,
      Partner,
      Step,
      SetPassWord,
    },
    provide() {
      return {
        refreshData: this.fetchData,
      }
    },
    data() {
      this.noticeWayMap = {
        0: '不通知',
        1: '短信通知',
      }

      // 合同状态  0.草稿 1.未完成 2.已完成 3.已失效 4.已失效-过期 5.已失效-拒签 6.已失效-撤回
      this.statusMapList = [
        {
          status: 0,
          text: '草稿',
          icon: '',
        },
        {
          status: 1,
          text: '未完成',
          icon: 'not_success',
        },
        {
          status: 2,
          text: '已完成',
          icon: 'success',
        },
        {
          status: 3,
          text: '已失效',
          icon: 'invalid_status',
        },
        {
          status: 4,
          text: '已失效-过期',
          icon: 'invalid_status',
        },
        {
          status: 5,
          text: '已失效-拒签',
          icon: 'invalid_status',
        },
        {
          status: 6,
          text: '已失效-撤回',
          icon: 'invalid_status',
        },
        {
          status: 7,
          text: '已失效-已作废',
          icon: 'invalid_status',
        },
        {
          status: 8,
          text: '作废中',
          icon: 'not_success',
        },
      ]
      this.noCreditSignTextMap = {
        shortText: '警告：此次签署允许签署方不实名签署',
        fullText:
          '警告：此次签署允许签署方不实名签署，如若有人未实名签署则生成的文件可能无法律效力（具体以不同国家的法律规定为准），如您有疑惑，请与发起方协商',
      }
      return {
        // tips 是否为展开状态
        isTipsExpanded: false,
        isDescExpanded: true,
        // 当前活动标签
        active: 0,
        businessId: undefined,
        // 发起方信息
        launcherInfo: {
          // 公司名称
          comName: undefined,
          // 操作人名称
          handlerName: undefined,
          // 完成时间
          launchTime: undefined,
          // 发起方头像
          launcherHeadUrl: undefined,
          // 发起人名称
          launchUserName: undefined,
          // 发起方身份：false.企业身份 true.个人身份
          launchUserRole: false,
        },
        // 撤回Model
        revokeModel: {
          businessId: undefined,
          // 撤回原因
          revokeReason: undefined,
        },
        // 拒签签署model
        returnApproveModel: {
          approveResult: false,
          revokeReason: undefined,
          subId: undefined,
          signPwd: undefined,
          verifyCode: undefined,
          verifyType: undefined,
        },
        detail: {
          // 是否允许接收方拒签文件0-否 1-是
          allowReturn: undefined,
          // 指定抄送人：0.不指定 1.指定
          assignSender: undefined,
          // 合同业务Id
          businessId: undefined,
          // 合同业务标题
          businessTitle: undefined,
          // 截止时间
          byTime: undefined,
          // 描述
          descName: undefined,
          // 相关资料列表
          fileRelatedList: [],
          // 文件列表
          fileRespList: [],
          // 是否是发起人
          isLauncher: undefined,
          // 发起时间
          launchTime: undefined,
          // 发起方
          launcher: undefined,
          // 发起人姓名
          launchUserName: undefined,
          // 发起方身份：false.企业身份 true.个人身份
          launchUserRole: false,
          // 发起方头像
          launcherHeadUrl: undefined,
          // 是否允许签署方不实名签署 0-否 1-是
          noCreditSign: undefined,
          // 通知方式：0.不通知 1.短信通知
          noticeWay: undefined,
          // 参与方列表
          partnerList: [],
          // 指定抄送人列表
          sendUserList: [],
          // 是否顺序签署0-否 1-是
          signOrderFlag: undefined,
          // 签署方式：0.不指定 1.短信验证 2.密码验证
          signWay: undefined,
          // 合同状态 0.草稿 1.未完成 2.已完成 3.已失效
          status: 1,
          // 待处理人
          toHandler: undefined,
          // 签署有效期
          validDay: undefined,
        },
        // 是否展示撤回dialog
        showRevokeDialog: false,
        revokeLoading: false,
        // 是否展示拒签dialog
        showReturnApproveDialog: false,
        returnApproveLoading: false,
        // 展示加入签署 QrCode
        showJoinQrCode: false,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      tipsText() {
        const { shortText, fullText } = this.noCreditSignTextMap
        return this.isTipsExpanded ? fullText : shortText
      },
      tipsButtonText() {
        return this.isTipsExpanded ? '收起' : '展开'
      },
      statusClass() {
        return this.statusMapList[this.detail.status].icon
      },
      curStatus() {
        return this.detail.status
      },
      isLauncher() {
        return !!this.detail.isLauncher
      },
      isFailed() {
        return this.detail.status === 3
      },
      isCompletedStatus() {
        return this.detail.status === 2 || this.detail.status === 7
      },
      // 作废中
      isNullifyFalg() {
        return this.detail.status === 8
      },
      // 是否展示撤回按钮
      showRevokeButton() {
        // 发起方
        // 状态为 未完成 status=1
        return this.isLauncher && this.curStatus === 1
      },
      // 是否展示拒签按钮
      showReturnApproveButton() {
        // 不是发起方
        // 已经到当前参与方了
        // 允许拒签
        return (
          !this.isLauncher &&
          !!this.getCurPartner() &&
          this.detail.allowReturn === 1
        )
      },
      // 是否展示拒签作废按钮
      showReturnNullifyButton() {
        // 不是发起方
        // 已经到当前参与方了
        // 允许拒签
        return !!this.getCurPartner() && this.detail.status === 8
      },
      // 是否展示下方的操作按钮，如果还没到当前参与方，那么就不应该展示操作按钮
      showOperateButton() {
        // 未完成
        return this.curStatus === 1 && !!this.getCurPartner()
      },
      // 是否展示马上处理按钮
      showOperateFirstButton() {
        // 到当前参与方才展示
        return !!this.getCurPartner()
      },
      // 是否展示催签
      urgeVisible() {
        if (this.detail.partnerList.length > 0) {
          const status1 = this.detail.partnerList.find(x => {
            return x.urgingSignature === 1
          })
          const status2 = this.detail.partnerList.find(x => {
            return x.urgingSignature === 2
          })
          if (status1) return 1
          if (status2) return 2
        }
        return 0
      },
      hasFileDelete() {
        return !this.userInfo.comId || this.hasAuth(['files_delete'])
      },
    },
    async created() {
      this.getQuery()
      await this.fetchData()

      // 小程序分享 页面
      if (isWxMiniProgram()) {
        postShareMessageToMini({
          title: this.detail.businessTitle,
          url: encodeURIComponent(this.$route.fullPath),
        })
      }
    },
    mounted() {
      this.$store.commit('sign/clearSignMsg')
      this.$nextTick(() => {
        this.calculateMainHeight()
      })
    },
    methods: {
      hasAuth,
      handleDelete() {
        Dialog.confirm({
          title: '提示',
          message: '删除后无法再查看此文件，是否确认删除？',
        })
          .then(() => {
            // on confirm
            this.deleteContract()
          })
          .catch(() => {
            // on cancel
          })
      },
      async deleteContract() {
        const params = {
          businessId: this.detail.businessId,
          partnerId: this.detail.partnerId,
          subId: this.detail.subId,
        }
        const data = await contractBusinessApi.deleteContract(params)
        this.$toast('删除成功')
        // window.history.back()
        this.$router.replace({
          name: 'List',
        })
      },
      async handleUrgeAll() {
        if (this.urgeVisible !== 1) return
        try {
          const params = {
            urgingSignatureReqParams: [],
          }
          this.detail.partnerList.forEach(x => {
            if (x.urgingSignature === 1) {
              params.urgingSignatureReqParams.push({
                businessId: this.detail.businessId,
                partnerId: x.partnerId,
              })
            }
          })
          await contractBusinessApi.updateUrgingSignature(params)
          this.$toast('催签成功')
          await this.fetchData()
        } catch (error) {
          console.error(error)
        }
      },
      async fetchData() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '加载中',
            forbidClick: false,
          })
          await this.getDetail()
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance.clear()
        }
      },
      getQuery() {
        this.businessId = this.$route.query.businessId
      },
      async getDetail() {
        try {
          const model = {
            businessId: this.businessId,
          }
          if (
            Number(this.$route.query.fileStatus) === 8 ||
            Number(this.$route.query.fileStatus) === 7
          ) {
            this.detail = await contractBusinessApi.queryInvalidBusinessDetail(
              model
            )
            console.log('this.detail: ', this.detail)
          } else {
            this.detail = await contractBusinessApi.detail(model)
            console.log('this.detail: ', this.detail)
          }
          const {
            launcher,
            launchTime,
            partnerList,
            launcherHeadUrl,
            launchUserName,
            launchUserRole,
          } = this.detail
          this.launcherInfo = {
            ...this.launcherInfo,
            comName: launcher,
            launchTime,
            launcherHeadUrl,
            launchUserName,
            launchUserRole,
          }
        } catch (error) {
          console.error(error)
        }
      },

      // 计算底部主体区域的高度
      calculateMainHeight() {
        const { signDetailTips, signDetailHeader, signDetailMain } = this.$refs
        const { body } = document
        let signTipsHeight = 0
        if (signDetailTips) {
          signTipsHeight = signDetailTips.offsetHeight
        }
        const headerHeight = signDetailHeader.offsetHeight
        const bodyHeight = body.clientHeight
        // 加上 70 底部的高度，防止ios底部滑不上来的问题
        signDetailMain.style.minHeight = `${bodyHeight -
          signTipsHeight -
          headerHeight -
          8 +
          20}px`
      },
      // 邀请弹框展示
      handleShowJoinQrCode() {
        this.showJoinQrCode = true
      },

      // 展示撤回弹窗
      handleShowRevokeDialog() {
        if (!this.isLauncher || this.revokeLoading) return
        this.showRevokeDialog = true
      },

      // 关闭撤回弹窗回调
      onRevokeDialogClose() {
        this.showRevokeDialog = false
        this.resetRevokeModel()
      },

      // 展示拒签弹窗
      handleShowReturnApproveDialog() {
        if (!this.detail.allowReturn || this.returnApproveLoading) return
        this.showReturnApproveDialog = true
      },

      // 关闭拒签弹窗回调
      onReturnApproveDialogClose() {
        this.showReturnApproveDialog = false
        this.resetReturnApproveModel()
      },

      // 重置拒签model
      resetReturnApproveModel() {
        const defaultModel = {
          approveResult: false,
          revokeReason: undefined,
          subId: undefined,
          signPwd: undefined,
          verifyCode: undefined,
          verifyType: undefined,
        }
        this.returnApproveModel = {
          ...this.returnApproveModel,
          ...defaultModel,
        }
      },

      // 重置撤回model
      resetRevokeModel() {
        const defaultModel = {
          businessId: undefined,
          // 撤回原因
          revokeReason: undefined,
        }
        this.revokeModel = {
          ...this.revokeModel,
          ...defaultModel,
        }
      },

      // 撤回
      async revoke() {
        let loadingInstance
        try {
          if (!this.isLauncher || this.revokeLoading) return
          this.revokeLoading = true
          loadingInstance = this.$toast.loading({
            message: '处理中',
            forbidClick: false,
          })
          const model = {
            ...this.revokeModel,
            businessId: this.businessId,
          }
          await contractSignApi.revoke(model)
          // 拒签后刷新页面数据
          await this.fetchData()
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance.clear()
          this.revokeLoading = false
        }
      },

      // 找到当前的参与方
      getCurPartner() {
        let curPartner
        this.detail.partnerList.forEach(partner => {
          if (partner.isSelf) {
            curPartner = partner
          }
        })
        return curPartner
      },

      // 获取当前参与方的文件列表
      getCurPartnerFileList() {
        const fileList = []
        const curPartner = this.getCurPartner()
        if (curPartner.subList && curPartner.subList.length) {
          curPartner.subList.forEach(sub => {
            sub.subFileList.forEach(file => {
              fileList.push(file)
            })
          })
        }
        return fileList
      },

      // 获取当前参与方的第一个文件的subId
      getCurPartnerSubId() {
        const fileList = this.getCurPartnerFileList()
        if (fileList.length) {
          const { subId } = fileList[0]
          return subId
        }
        return undefined
      },

      // 打开输入密码组件
      handleShowSetPasswordComponent() {
        this.$store.commit('contractSign/setShowSignPassword', true)
      },

      // 输入密码成功回调
      async onSetPasswordConfirm(param) {
        this.returnApproveModel = {
          ...this.returnApproveModel,
          ...param,
        }
        await this.returnApprove()
      },

      // 拒签
      async returnApprove() {
        let loadingInstance
        try {
          if (!this.detail.allowReturn || this.returnApproveLoading) return
          this.returnApproveLoading = true
          loadingInstance = this.$toast.loading({
            message: '处理中',
            forbidClick: false,
          })
          this.returnApproveModel.subId = this.getCurPartnerSubId()
          if (this.returnApproveModel.subId) {
            if (this.isNullifyFalg) {
              await contractSignApi.nullifyApprove(this.returnApproveModel)
            } else {
              await contractSignApi.approve(this.returnApproveModel)
            }
            this.$store.commit('contractSign/setShowSignPassword', false)
            // 拒签成功后  刷新数据
            await this.fetchData()
          }
        } catch (error) {
          this.$store.commit('contractSign/setErrMsg', error.msg)
          console.error(error)
        } finally {
          loadingInstance.clear()
          this.returnApproveLoading = false
        }
      },

      // 获取第一个待处理的文件
      getFirstFileToBeProcess() {
        // 未处理的文件的subStatus 0.待填写 1.待审批 2.待签署 3.待签章
        const unOperateStatusList = [0, 1, 2, 3]
        const fileList = this.getCurPartnerFileList()
        return fileList.find(file =>
          unOperateStatusList.includes(file.subStatus)
        )
      },

      // 当前参与方是否要去认证
      async getComOrUserStatus() {
        try {
          const subId = this.getCurPartnerSubId()
          console.log('status', this.detail.status)
          if (this.detail.status === 8 || this.detail.status === 7) {
            return await contractSignApi.getInvalidComOrUserStatus({
              partnerSubId: subId,
            })
          }
          return await contractSignApi.getComOrUserStatus({
            partnerSubId: subId,
          })
        } catch (error) {
          console.error(error)
        }
      },

      // 马上处理，直接处理第一个还未处理的文件
      async handleOperateFirstFile() {
        try {
          const firstFile = this.getFirstFileToBeProcess()
          const creditRes = await this.getComOrUserStatus()
          // creditStatus 认证状态true：认证成功 false：认证失败
          // creditType 认证类型 0：企业 1个人
          const { creditStatus, creditType } = creditRes
          if (!creditStatus) {
            // 没有认证
            if (creditType === 1) {
              // 个人认证
              // Dialog.alert({
              //   title: '提示',
              //   message: '您还没有认证,请先去实名认证',
              //   confirmButtonText: '去认证',
              //   className: 'sign-detail-dialog',
              // }).then(() => {
              this.$router.push({
                path: 'signAuth',
                query: {
                  url: this.$route.fullPath,
                },
              })
              // })
            } else if (creditType === 0) {
              // 企业未认证
              await Dialog.alert({
                title: '提示',
                message: '无法签署，请先提醒企业创建者/法人进行企业认证!',
                confirmButtonText: '确定',
                className: 'sign-detail-dialog',
              })
            }
          } else {
            // 已经认证
            if (firstFile) {
              const { defaultName } = await this.getCurPartner()
              const { subId } = firstFile
              const query = {
                subId,
                isLauncher: this.detail.isLauncher,
                businessId: this.businessId,
                defaultName,
                fileStatus: this.detail.status,
              }
              await this.$router.push({
                name: 'signFile',
                query,
              })
            }
          }
        } catch (error) {
          console.error(error)
        }
      },

      handleToggleExpand() {
        this.isTipsExpanded = !this.isTipsExpanded
      },
      handleToggleExpandDesc() {
        this.isDescExpanded = !this.isDescExpanded
      },

      /**
       * 完成状态的订单 去往 凭证报告页面
       */
      handleToEvidencePage() {
        // debugger
        this.$router.push({
          name: 'signEvidence',
          query: {
            businessId: this.businessId,
            fileStatus: this.detail.status,
          },
        })
      },

      async handleDeleteFile() {
        let loadingInstance
        try {
          await this.$dialog.alert({
            title: '提示',
            message: '您确定要删除该合同吗？',
            showCancelButton: true,
          })
          const model = {
            businessId: this.businessId,
          }
          loadingInstance = this.$toast.loading({
            message: '处理中',
            forbidClick: false,
          })
          await contractSignApi.delete(model)
          this.$router.replace({
            name: 'List',
          })
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance.clear()
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .sign-detail-page {
    width: 100%;
    background: #f4f5f6;
    height: 100%;

    .sign-detail-tips {
      width: 100%;
      // height: 46px;
      font-size: 14px;
      line-height: 22px;
      padding: 8px 13px;
      background: #fdf6ec;
      color: #ff941f;
      display: flex;
      justify-content: space-between;
      transition: all 0.3s;

      .tips-content {
        width: 296px;
      }

      .tips-button {
        color: @BLUE;
      }

      &.expanded {
        // height: 90px;
      }
    }

    .sign-detail-header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-card {
        margin: 12px;
        width: 100%;
        padding: 16px 16px 4px 16px;
        position: relative;
        border-radius: 6px;
        background: @WHITE;
        box-shadow: 0 5px 20px 0 rgba(197, 202, 213, 0.25);
        overflow: hidden;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;

        // &::before {
        //   position: absolute;
        //   content: '';
        //   left: 0;
        //   top: 0;
        //   right: 0;
        //   height: 2px;
        //   background: @BLUE;
        // }

        // &.not_success::before {
        //   background: #ff6e48;
        // }
        // &.success::before {
        //   background: @BLUE;
        // }
        // &.invalid_status::before {
        //   background: #c5c5c5;
        // }

        .card-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .card-title-text {
            color: #111a34;
            width: 230px;
            font-size: 18px;
            line-height: 24px;
            word-break: break-all;
            &.ellipsis {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .card-title-status {
            position: absolute;
            right: 0;
            top: 0;
            min-width: 68px;
            height: 25px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            .status-icon {
              font-size: 14px;
            }

            .status-text {
              display: inline-block;
              width: 100%;
              height: 100%;
              padding: 0 8px;
              box-sizing: border-box;
              text-align: center;
              font-size: 14px;
              line-height: 24px;
              border-radius: 0px 4px 0px 20px;
              font-weight: bold;
              &.not_success {
                background: #feeeec;
                color: #f36e2b;
              }
              &.success {
                background: #ebf3ff;
                color: #4285f4;
              }
              &.invalid_status {
                background: #c5c5c5;
              }
            }
          }
        }

        .card-desc {
          margin: 5px 0 8px;

          .desc-content-title {
            font-size: 12px;
            color: #677283;
            line-height: 26px;
          }
          .desc-content {
            max-height: 25px;
            font-size: 12px;
            line-height: 26px;
            color: #111a34;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            transition: all 1s;

            &.expanded {
              max-height: 500px;
              display: -webkit-box;
              -webkit-line-clamp: 10;
            }
          }
        }

        .launcher-info {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          .send-user {
            border-top: 1px solid #eeeeee;
            .send-user-list {
              display: flex;
              flex-wrap: wrap;
              // padding-left: 28px;
              text-align: center;
              padding-top: 8px;
              .user-list-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                margin-right: 10px;
                color: #111a34;
                font-size: 11px;
                img {
                  width: 38px;
                  height: 38px;
                  border-radius: 2px;
                  margin: 0 auto;
                }
                span {
                  margin-top: 4px;
                }
              }
            }
          }
        }
        .info-item {
          padding: 12px 0;
          border-top: 1px solid #eeeeee;
          &.borT {
            border-top: none;
          }
        }
        .info-item-title {
          font-size: 12px;
          color: #677283;
          line-height: 20px;
          margin-bottom: 4px;
          .item-icon {
            font-size: 18px;
            margin-right: 10px;
          }
        }
        .info-item-text {
          color: #111a34;
          font-size: 14px;
          // padding-left: 28px;
          line-height: 20px;
        }
        .expand-arrow {
          position: absolute;
          bottom: 16px;
          right: 20px;
          transition: all 0.5s;
          font-size: 12px;

          .reverse {
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
          }
        }

        // .urge {
        //   text-align: right;
        //   font-size: 12px;
        //   // margin-top: 12px;
        //   padding-bottom: 30px;
        //   .van-button {
        //     // width: 80px;
        //     height: 24px;
        //     font-size: 12px;
        //   }
        //   .van-button--disabled {
        //     background-color: #8a9ab4;
        //     border-color: #8a9ab4;
        //   }
        // }
      }
    }

    .sign-detail-main {
      // margin-top: 8px;
      background: @WHITE;
      padding-bottom: calc(@safe-area + 66px);

      .step-container {
        background: #fff;
        margin: 16px;

        .step {
          /deep/ .step-right {
            margin-bottom: 24px;
          }
        }

        .file-list-container {
          width: 341px;
          margin: 0 auto;
          border: 1px solid #f0f0f0;
          border-radius: 4px;
          padding: 0 16px 16px 16px;

          .title {
            margin: 16px 0;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: @TEXT-COLOR-1;
          }

          .file-component {
            margin-top: 16px;
          }
        }
        .file-list-container-new {
          padding: 0;
          border: 0;
          &::before {
            content: '';
            display: block;
            margin: 0 -17px;
            width: calc(~'100% + 34px');
            height: 13px;
            background: #eee;
          }
          .title {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: left;
            color: #111a34;
            line-height: 22px;
          }
        }
      }
    }

    .sign-detail-footer {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      background: @WHITE;
      box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px calc(@safe-area + 10px);
      z-index: 99;
      .footer-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        margin-right: 18px;
        .icon-btn {
          font-size: 16px;
        }
      }
      .van-button {
        transition: all 0.3s;
        flex: 1;
        height: 44px;
        padding: 0;

        &.btn-large {
          width: 100%;
        }

        .van-button__text {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .van-button + .van-button {
        margin-left: 10px;
      }
    }
    /deep/ .van-tab {
      flex: none;
      -webkit-flex: none;
      -webkit-box-flex: none;
      margin-right: 43px;
      &:first-child {
        margin-left: 16px;
      }
    }
    /deep/ .van-tab__text {
      font-size: 14px;
    }

    /deep/ .van-tabs__line {
      width: 56px;
    }
    /deep/ .van-tabs__wrap {
      border-bottom: 1px solid #eeeeee;
    }
  }
</style>

<style lang="less">
  .sign-detail-dialog {
    width: 306px;
    border-radius: 6px;

    .van-dialog__header {
      font-size: 20px;
      font-weight: 400;
    }

    .van-dialog__content {
      margin-top: 24px;
      padding: 0 24px;

      .dialog-description {
        font-size: 16px;
        color: @TEXT-COLOR-1;
      }

      .van-cell {
        margin-top: 24px;
        padding: 0;

        .van-field__body {
          .van-field__control {
            border: 1px solid #e2e4ea;
            border-radius: 4px;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .van-dialog__footer {
      margin-top: 20px;

      .van-button {
        height: 50px;
      }
    }
  }
</style>
