<template>
  <div>
    <div class="partner-status" v-if="!isPureLauncherComponent">
      <SvgIcon :type="partnerStatusMap.icon" class="icon" />
      <span :class="partnerStatusMap.className">{{
        partnerStatusMap.statusText
      }}</span>
    </div>
    <div
      class="partner"
      :class="{
        highlight: info.status != 2,
        highlight2: isPureLauncherComponent,
      }"
    >
      <div class="right">
        <!-- 参与方info -->
        <div class="partner-info" v-if="!isPureLauncherComponent">
          <span class="info">
            {{ info.defaultName }}
            <span v-if="info.customName">：{{ info.customName }}</span>
          </span>
          <span class="status">
            <!-- 已完成签署，但未认证 -->
            <SvgIcon
              type="not_approved"
              class="icon"
              v-if="
                !info.realName &&
                  info.status === 3 &&
                  detail.noWaterLineStatus == 0
              "
            />
            <!-- 已完成签署，但未认证 -->
          </span>
        </div>
        <!-- 参与方info -->
        <!-- 企业名称 经办人 -->
        <!-- 个人身份不展示企业名称 -->
        <div class="com-name" v-if="!curPartnerIsPersonal">
          <span v-if="isPureLauncherComponent" class="sponsor">发起方</span>
          {{ info.comName }}
          <!-- <SvgIcon
            v-if="isShowEditPartnerInfo"
            type="edit"
            class="icon-edit-info"
            @click="handleShowPartnerEditDialog"
          /> -->
        </div>
        <!-- 个人身份展示 handlerName -->
        <div class="com-name" v-else-if="curPartnerIsPersonal">
          {{ info.handlerName }}
          <template v-if="info.handlerTel"> ({{ info.handlerTel }})</template>

          <!-- <SvgIcon
            v-if="isShowEditPartnerInfo"
            type="edit"
            class="icon-edit-info"
            @click="handleShowPartnerEditDialog"
          /> -->
        </div>
        <div
          class="handler-info"
          v-if="!isPureLauncherComponent && info.status !== 3"
        >
          <!-- 外部个人不展示 经办人三个字 -->
          <span v-if="partnerRole !== 1 && partnerRole !== 0">经办人：</span>
          <!-- 内部参与方 用 assignTypeName -->
          <template v-if="partnerRole === 0">
            <span>{{ info.assignTypeName }}</span>
            <span
              v-if="info.assignUserNameList && info.assignUserNameList.length"
              >(<span
                v-for="(assignUserName, index) in info.assignUserNameList"
                :key="index"
                >{{ assignUserName
                }}<i v-if="index < info.assignUserNameList.length - 1"
                  >、</i
                ></span
              >)
            </span>
          </template>
          <!-- 内部参与方 用assignTypeName -->
          <template v-else-if="!curPartnerIsPersonal">
            <span>{{ info.handlerName }}</span>
            <span>({{ info.handlerTel }})</span>
          </template>
          <div v-if="isShowEditPartnerInfo" class="complete-tips">
            请补充身份信息或使用二维码邀请对方加入
          </div>
        </div>
        <!-- 企业名称 经办人 -->
        <!-- 发起 -->
        <div class="desc" v-if="isPureLauncherComponent">
          <!-- false-企业身份 展示发起人名字  true-个人身份 不展示发起人名字  -->
          <span v-if="!info.launchUserRole">{{ info.launchUserName }}</span
          >发起于{{ (info.launchTime * 1000) | datetimeFormat }}
        </div>

        <!-- 发起 -->
        <!-- 已完成 -->
        <div class="desc" v-else-if="info.status === 3">
          <span v-if="curPartnerIsPersonal">{{ info.handlerName }}</span>
          <span v-else-if="partnerRole === 0"
            >{{ info.assignTypeName }}
            <span
              v-if="info.assignUserNameList && info.assignUserNameList.length"
              >(<span
                v-for="(assignUserName, index) in info.assignUserNameList"
                :key="index"
                >{{ assignUserName }}
                <i v-if="index < info.assignUserNameList.length - 1"
                  >、</i
                > </span
              >)
            </span>
          </span>
          <span v-else>
            {{ info.comName }}
            <span
              v-if="info.assignUserNameList && info.assignUserNameList.length"
              >(<span
                v-for="(assignUserName, index) in info.assignUserNameList"
                :key="index"
                >{{ assignUserName }}
                <i v-if="index < info.assignUserNameList.length - 1"
                  >、</i
                > </span
              >)
            </span> </span
          >于{{ (info.finishTime * 1000) | datetimeFormat }}完成
        </div>
        <!-- 已完成 -->
        <div
          class="operation-content"
          :class="{
            borB:
              (info.urgingSignature == 1 ||
                info.urgingSignature == 2 ||
                isShowEditPartnerInfo ||
                isShowQrCode) &&
              (isCur || isLauncher),
          }"
          v-if="
            info.urgingSignature == 1 ||
              info.urgingSignature == 2 ||
              isShowEditPartnerInfo ||
              isShowQrCode
          "
        >
          <!-- 催签 -->
          <div
            class="urge"
            v-if="info.urgingSignature == 1 || info.urgingSignature == 2"
          >
            <van-popover
              v-model="showPopover"
              placement="bottom-start"
              trigger="click"
              theme="dark"
            >
              <div style="font-size:13px;padding:11px;line-height:18px;">
                催签后系统会再次发短信邮件提醒对方签署（每份文件每天只能催签一次哦）
              </div>
              <template #reference>
                <img src="@/assets/imgs/tip.png" />
              </template>
            </van-popover>
            <div
              class="join-sign-qrCode"
              :disabled="info.urgingSignature == 2"
              @click="handleUrge"
            >
              {{ info.urgingSignature == 1 ? '催签' : '已催' }}
            </div>
          </div>
          <!-- 修改身份 -->
          <div
            class="join-sign-qrCode"
            v-if="isShowEditPartnerInfo"
            @click="handleShowPartnerEditDialog"
          >
            修改身份信息
          </div>
          <!-- 邀请Ta -->
          <div
            class="join-sign-qrCode"
            v-if="isShowQrCode"
            @click="handleShowJoinQrCode"
          >
            邀请TA
            <SvgIcon type="icon_right" class="icon-qrcode" />
          </div>
        </div>
        <!-- 参与方操作以及文件列表 -->
        <div
          class="sub-list"
          v-if="
            (isCur || info.isPartner || isLauncher) &&
              info.subList &&
              info.subList.length > 0
          "
        >
          <div
            class="sub-list-item"
            v-for="(sub, index) in info.subList"
            :key="index"
          >
            <div class="operate-type">
              {{ index + 1 }}.{{ operateTypeMap[sub.operateType].title }}
            </div>
            <template v-if="info.isPartner || isLauncher">
              <div class="file-list">
                <FileComponent
                  v-for="file in sub.subFileList"
                  :key="file.fileKey"
                  :file="file"
                  :signStatus="detail.status"
                  :business-id="businessId"
                  :is-launcher="isLauncher"
                  :no-credit-sign="detail.noCreditSign"
                  :real-name="info.realName"
                  :can-operate="sub.allowOperate"
                  :isGrey="info.status === 2"
                />
              </div>
            </template>
          </div>
        </div>

        <template v-if="canOperate">
          <div class="relate-file-list" v-if="detail.fileRelatedList.length">
            <div class="title">参考资料</div>
            <div class="file-list">
              <FileComponent
                v-for="file in detail.fileRelatedList"
                :key="file.fileKey"
                :file="file"
                :can-operate="false"
                :showDownload="true"
              />
            </div>
          </div>
        </template>
        <!-- 操作以及文件列表 -->
      </div>

      <PartnerInfoEdit
        v-if="!isPureLauncherComponent && showPartnerEditDialog"
        v-model="showPartnerEditDialog"
        :partnerRole="partnerRole"
        :info="info"
        @onConfirm="onEditDialogConfirm"
        append-to-body
      />
      <JoinQrCode
        v-if="!isPureLauncherComponent && isLauncher && showJoinQrCode"
        v-model="showJoinQrCode"
        :detail="detail"
        :partner-info="info"
        append-to-body
      />
    </div>
  </div>
</template>

<script>
  import api from '@/api/contractBusiness'
  import FileComponent from './FileComponent.vue'
  import PartnerInfoEdit from './PartnerInfoEdit.vue'
  import JoinQrCode from './JoinQrCode.vue'

  export default {
    name: 'Partner',
    components: { FileComponent, PartnerInfoEdit, JoinQrCode },
    inject: ['refreshData'],
    props: {
      // 可选参数,detail
      detail: {
        type: Object,
        default: () => {},
      },
      info: {
        type: Object,
        default: () => {},
      },
      // 当前用户是否为当前的参与方
      isCur: {
        type: Boolean,
        default: false,
      },
      // 是否为发起组件，如果是发起组件则只需要展示公司名 发起人 发起时间
      isPureLauncherComponent: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      businessId() {
        return this.detail?.businessId
      },
      // 是否为发起方
      isLauncher() {
        return this.detail?.isLauncher
      },

      // 是否展示加入签署二维码
      // 发起方
      // 当前参与方为非内部参与方
      // 状态为 待加入 待处理 处理中
      isShowQrCode() {
        return (
          this.isLauncher &&
          this.partnerRole !== 0 &&
          !this.isPureLauncherComponent &&
          this.info.status <= 2
        )
      },

      // 是否展示编辑参与方信息按钮
      // 发起方
      // 状态为 未加入
      isShowEditPartnerInfo() {
        return (
          this.info.status === 0 &&
          this.isLauncher &&
          !this.isPureLauncherComponent
        )
      },

      // 参与方身份
      // 0-内部 1-外部个人 2-外部企业
      partnerRole() {
        return this.info.partnerRole
      },

      // 是否为外部个人
      isPersonal() {
        return this.partnerRole === 1
      },

      // 当前参与方是否为个人身份
      curPartnerIsPersonal() {
        // 内部参与方
        if (this.partnerRole === 0) {
          // 内部参与方 角色判断 0-个人 1-企业
          return this.info.internalRole === 0
        }
        return this.partnerRole === 1
      },

      // 参与方状态
      partnerStatusMap() {
        // 0.待加入 1.待处理 2.处理中 3.已完成 4.已失效
        const { status } = this.info
        let statusText = ''
        let className = ''
        let icon = ''
        switch (status) {
          case 1:
            statusText = '待处理'
            className = 'wait_process'
            icon = 'pending_next'
            break
          case 2:
            statusText = '处理中'
            className = 'processing'
            icon = 'pending_current_1'
            break
          case 3:
            statusText = '已完成'
            className = 'success'
            icon = 'success_1'
            break
          case 4:
            statusText = '已失效'
            className = 'invalid'
            icon = 'invalid_status'
            break
          default:
            statusText = '待加入'
            className = 'wait_join'
            icon = 'not_join'
        }
        return {
          statusText,
          className,
          icon,
        }
      },

      canOperate() {
        const subList = this.info?.subList
        if (subList && subList.length) {
          return this.info.subList.some(sub => sub.allowOperate)
        }
        return false
      },
    },
    data() {
      this.operateTypeMap = {
        // 参与方操作类型：0-个人审批 1-个人签署 2-经办人签署 3-法人签署 4-企业签章 5-仅填写变量
        0: {
          title: '个人审批',
          buttonText: '审批',
        },
        1: {
          title: '个人签署',
          buttonText: '签署',
        },
        2: {
          title: '经办人签署',
          buttonText: '签署',
        },
        3: {
          title: '法人签署',
          buttonText: '签署',
        },
        4: {
          title: '企业签章',
          buttonText: '签署',
        },
        5: {
          title: '仅填写变量',
          buttonText: '去填写变量',
        },
        6: {
          title: '仅填写变量',
          buttonText: '去填写变量',
        },
      }
      return {
        // 展示参与方信息编辑 dialog
        showPartnerEditDialog: false,
        // 展示加入签署 QrCode
        showJoinQrCode: false,
        showPopover: false,
      }
    },
    created() {},
    methods: {
      // 催签
      async handleUrge() {
        if (this.info.urgingSignature === 2) return
        try {
          const params = {
            urgingSignatureReqParams: [
              {
                businessId: this.detail.businessId,
                partnerId: this.info.partnerId,
              },
            ],
          }
          await api.updateUrgingSignature(params)
          this.info.urgingSignature = 2
          this.$toast('催签成功')
        } catch (error) {
          console.error(error)
        }
      },
      handleShowPartnerEditDialog() {
        this.showPartnerEditDialog = true
      },

      async onEditDialogConfirm(value) {
        try {
          await this.modifyPartnerInfo(value)
        } catch (error) {
          console.error(error)
        }
      },

      handleShowJoinQrCode() {
        this.showJoinQrCode = true
      },

      async modifyPartnerInfo(model) {
        try {
          if (this.loading) return
          this.loading = true
          const { partnerId } = this.info
          let modifyModel = {
            partnerId,
          }
          if (this.isPersonal) {
            // 外部个人
            // modifyModel.handlerName =
            modifyModel = {
              ...modifyModel,
              ...model,
            }
          } else {
            modifyModel = {
              ...modifyModel,
              ...model,
            }
          }
          // console.log(modifyModel)

          await api.modifyPartnerInfo(modifyModel)
          await this.refreshData()
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="less">
  .van-popover--dark {
    .van-popover__arrow {
      color: #000;
      opacity: 0.65;
    }
    .van-popover__content {
      background-color: rgba(0, 0, 0, 0.65);
      width: 210px;
    }
  }
  .van-popover[data-popper-placement='top-end'] .van-popover__arrow {
    right: 0.285rem;
  }
</style>
<style scoped lang="less">
  .partner-status {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    .icon {
      margin-right: 5px;
      font-size: 18px;
    }
    .wait_process {
      color: #fe943d;
    }
    .processing {
      color: #eb5050;
    }
    .success {
      color: #16bb83;
    }
    .invalid {
      color: @TEXT-COLOR-1;
    }
    .wait_join {
      color: @TEXT-COLOR-1;
    }
  }
  .partner {
    // width: 324px;
    min-height: 75px;
    border: 1px solid #eb5050;
    border-radius: 4px;
    display: flex;
    padding: 8px 16px 12px 12px;
    transition: all 0.3s;
    position: relative;

    .left {
      margin-right: 13px;

      .partner-avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #ededed;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }

        .avatar {
          font-size: 42px;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .partner-info {
        height: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .info {
          display: inline-flex;
          align-items: center;
          height: 20px;
          padding: 0 4px;
          font-size: 14px;
          line-height: 14px;
          color: #fff;
          font-weight: 500;
          // border: 1px solid @BLUE;
          margin-left: -12px;
          background: #8a9ab4;
          border-radius: 2px 0px 0px 2px;
          box-sizing: border-box;
          border-radius: 0 2px 2px 0;
        }

        .status {
          display: inline-flex;
          align-items: center;
          height: 20px;
          line-height: 20px;
          margin-left: 8px;

          .icon {
            display: flex;
            align-items: center;
            height: 20px;
            line-height: 20px;
            font-style: normal;

            /deep/ svg {
              width: 88px;
              height: 20px;
            }
          }
        }
      }

      .com-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #111a34;
        word-break: break-all;
        .icon-edit-info {
          margin-left: 8px;
          font-size: 11px;
          line-height: 11px;
        }
        .sponsor {
          padding-right: 13px;
          position: relative;
          &::after {
            content: '';
            width: 3px;
            height: 3px;
            background: #111a34;
            border-radius: 50%;
            position: absolute;
            right: 5px;
            top: 8px;
          }
        }
      }

      .handler-info {
        font-size: 12px;
        color: @TEXT-COLOR-1;
        margin-top: 8px;
        .complete-tips {
          margin-top: 8px;
          font-size: 12px;
          color: #8a9ab4;
        }
      }

      .desc {
        font-size: 12px;
        color: #677283;
        line-height: 20px;
      }

      .sub-list {
        font-size: 12px;

        .sub-list-item {
          .operate-type {
            margin: 8px 0;
            line-height: 20px;
            color: @TEXT-COLOR-0;
          }
        }
      }

      .urge {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: -8px;
        img {
          width: 16px;
          height: 16px;
          // margin-top: 12px;
          margin-left: 8px;
        }
        .join-sign-qrCode {
          margin-left: 11px;
        }
      }

      .file-list {
        .file-component + .file-component {
          margin-top: 8px;
        }
      }

      .relate-file-list {
        padding-left: 8px;
        .title {
          font-size: 12px;
          margin: 8px 0;
          color: #8a9ab4;
        }
      }
    }
    .operation-content {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .van-popover__wrapper {
        display: flex;
      }
    }
    .borB {
      margin-bottom: 22px;
      position: relative;
      &::before {
        content: '';
        height: 1px;
        position: absolute;
        left: -12px;
        right: -16px;
        bottom: -16px;
        background: #eb5050;
        -webkit-transform: scale(1, 0.5);
        -ms-transform: scale(1, 0.5);
        -o-transform: scale(1, 0.5);
        transform: scale(1, 0.5);
      }
    }
    .join-sign-qrCode {
      height: 24px;
      font-size: 12px;
      color: #1676ff;
      text-align: center;
      line-height: 22px;
      background: #ffffff;
      border: 1px solid #1676ff;
      border-radius: 2px;
      // margin-top: 12px;
      margin-right: 16px;
      padding: 0 6px;
      .icon-qrcode {
        font-size: 13px;
      }
    }
    &.highlight {
      background: #f4f5f6;
      border: 1px solid #eeeeee;
      .borB {
        &::before {
          background: #c1c4cd;
        }
      }
    }
    &.highlight2 {
      background: #fff;
      box-shadow: 0px 2px 7px 0px #f6f8fb;
      padding: 13px;
      .com-name {
        font-size: 15px;
      }
      .desc {
        font-size: 13px;
      }
    }
  }
</style>
